import React, { useEffect, useState, useContext, useLayoutEffect, useRef } from 'react';
import { Card, CardBody, CardHeader, Table, Row, Col, Badge, Button, Modal, ModalHeader, ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Icon } from '@iconify/react';
import homeCity from '@iconify/icons-mdi/home-city';
import accountBox from '@iconify/icons-mdi/account-box';
import accountIcon from '@iconify/icons-mdi/account';
import pillIcon from '@iconify/icons-mdi/pill';
import currencyUsd from '@iconify/icons-mdi/currency-usd';
import calendarMonth from '@iconify/icons-mdi/calendar-month';
import cashIcon from '@iconify/icons-mdi/cash';
import clipboardCheck from '@iconify/icons-mdi/clipboard-check';
import printerIcon from '@iconify/icons-mdi/printer';
import Loader from '../components/Loader';
import { UserContext } from '../contexts/UserContext';
import SimpleBar from 'simplebar-react';
import cashLock from '@iconify/icons-mdi/cash-lock';
import { Switch, Route } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import arrowTopRightBoldBoxOutline from '@iconify/icons-mdi/arrow-top-right-bold-box-outline';
import { SelectedFacilitiesContext } from '../contexts/SelectedFacilitiesContext';
import menuUpOutline from '@iconify/icons-mdi/menu-up-outline';
import menuDownOutline from '@iconify/icons-mdi/menu-down-outline';
import arrowDownThin from '@iconify/icons-mdi/arrow-down-thin';
import arrowUpThin from '@iconify/icons-mdi/arrow-up-thin';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import pillOff from '@iconify/icons-mdi/pill-off';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import commentText from '@iconify/icons-mdi/comment-text';
import noteTextOutline from '@iconify/icons-mdi/note-text-outline';
import useMediprocityScript from '../custom-hooks/useMediprocityScript';
import useAlertsUserSettings from '../custom-hooks/useAlertsSortOrder';
import { Helmet } from 'react-helmet';
import TableRowDetailModal from './TableRowDetailModal';
// import { mockHighCostData } from '../mock-data/mockHighCostData';
import cloneDeep from 'lodash.clonedeep';
import classNames from 'classnames';
import hospitalBox from '@iconify/icons-mdi/hospital-box';
import { SrxApi } from '../api/SrxApi';
import { WidgetsData } from '../data/WidgetsData';
import refreshIcon from '@iconify/icons-mdi/refresh';
import { useAtom } from 'jotai';
import { ActiveAlertContext } from '../contexts/ActiveAlertContext';
import { CurrentTimeContext } from '../contexts/CurrentTimeContext';
import useRefreshAlerts from '../custom-hooks/useRefreshAlerts';
import {
    highCostAtomUserSettings,
    highCostAlertsAtomUserSettings,
    patMissingAtomUserSettings,
    clarificationsAtomUserSettings,
    priorAuthAtomUserSettings,
    nonCoveredAtomUserSettings,
    therapeuticIntAtomUserSettings,
    admissionMedsAtomUserSettings,
    refillTooSooAtomUserSettings,
    pumpPickupAtomUserSettings,
    narcoticsRefillAtomUserSettings,
} from '../jotai-atoms/AlertAtoms';

const AlertsDashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { activeAlert, setActiveAlert } = useContext(ActiveAlertContext);
    const { user } = useContext(UserContext);

    //  set alerts sort and show settings
    useAlertsUserSettings();

    const [highCostUserSettings, setHighCostUserSettings] = useAtom(highCostAtomUserSettings);
    const [highCostAlertsUserSettings, setHighCostAlertsUserSettings] = useAtom(highCostAlertsAtomUserSettings);
    const [nonCoveredUserSettings, setNonCoveredUserSettings] = useAtom(nonCoveredAtomUserSettings);
    const [clarificationsUserSettings, setClarificationsUserSettings] = useAtom(clarificationsAtomUserSettings);
    const [priorAuthUserSettings, setPriorAuthUserSettings] = useAtom(priorAuthAtomUserSettings);
    const [therapeuticIntUserSettings, setTherapeuticIntUserSettings] = useAtom(therapeuticIntAtomUserSettings);
    const [admissionMedsUserSettings, setAdmissionMedsUserSettings] = useAtom(admissionMedsAtomUserSettings);
    const [patMissingUserSettings, setPatMissingUserSettings] = useAtom(patMissingAtomUserSettings);
    const [refillTooSoonUserSettings, setRefillTooSoonUserSettings] = useAtom(refillTooSooAtomUserSettings);
    const [pumpPickupUserSettings, setpumpPickupUserSettings] = useAtom(pumpPickupAtomUserSettings);
    const [narcoticsRefillUserSettings, setNarcoticsRefillUserSettings] = useAtom(narcoticsRefillAtomUserSettings);

    let history = useHistory();

    useEffect(() => {
        if (activeAlert)
            setIsModalOpen(true);
        else
            setIsModalOpen(false);
    }, [activeAlert]);

    const toggleModal = () => {
        if (activeAlert)
            setActiveAlert(null);
        setIsModalOpen(!isModalOpen);
    };

    const { faciToken, setFaciToken } = useContext(UserContext);

    function statusPill(status) {
        if (status === 'pending') {
            return (<Badge className='bg-warning'>
                {status}
            </Badge>);
        } else if (status === 'approved') {
            return (<Badge className='bg-success'>
                {status}
            </Badge>);
        } else if (status === 'denied') {
            return (<Badge className='bg-danger'>
                {status}
            </Badge>);
        } else if (status === 'completed') {
            return (<Badge className='bg-info'>
                {status}
            </Badge>);
        }

        return (<Badge className='bg-info'>
            {status}
        </Badge>);
    }


    // console.log(WidgetsData);
    // const [numberOfAlertsToDisplay, setNumberOfAlertsToDisplay] = useState({
    //     highCostAlerts: 15
    // })

    // if(loading){
    //     return(
    //         <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
    //     <div>
    //     <h4>Loading alerts...</h4>
    //         <div className="bouncing-loader">
    //             <div></div>
    //             <div></div>
    //             <div></div>
    //         </div>
    //         </div>
    //     </div>
    //     )
    // }

    const alertsConfig = () => {
        WidgetsData.forEach((req) => {
            const getSortOrder = (alertName) => {
                switch (alertName) {
                    case 'highCost':
                        return { sortOrder: highCostUserSettings.sortOrder, hidden: highCostUserSettings.hidden };
                    case 'highcostalerts':
                        return { sortOrder: highCostAlertsUserSettings.sortOrder, hidden: highCostAlertsUserSettings.hidden };
                    case 'clarifications':
                        return { sortOrder: clarificationsUserSettings.sortOrder, hidden: clarificationsUserSettings.hidden };
                    case 'priorAuth':
                        return { sortOrder: priorAuthUserSettings.sortOrder, hidden: priorAuthUserSettings.hidden };
                    case 'nonCovered':
                        return { sortOrder: nonCoveredUserSettings.sortOrder, hidden: nonCoveredUserSettings.hidden };
                    case 'therapeuticInt':
                        return { sortOrder: therapeuticIntUserSettings.sortOrder, hidden: therapeuticIntUserSettings.hidden };
                    case 'admissionMeds':
                        return { sortOrder: admissionMedsUserSettings.sortOrder, hidden: admissionMedsUserSettings.hidden };
                    case 'patMissing':
                        return { sortOrder: patMissingUserSettings.sortOrder, hidden: patMissingUserSettings.hidden };
                    case 'refillTooSoon':
                        return { sortOrder: refillTooSoonUserSettings.sortOrder, hidden: refillTooSoonUserSettings.hidden };
                    case 'pumpPickup':
                        return { sortOrder: pumpPickupUserSettings.sortOrder, hidden: pumpPickupUserSettings.hidden };
                    case 'narcoticsRefill':
                        return { sortOrder: narcoticsRefillUserSettings.sortOrder, hidden: narcoticsRefillUserSettings.hidden };
                    default:
                        break;
                }
            };
            let sortandHide = getSortOrder(req.category);
            WidgetsData[WidgetsData.findIndex(i => i.category === req.category)] = { ...req, ...sortandHide };
        });
    };

    useEffect(() => {
        alertsConfig();
    }, []);

    if (WidgetsData.length === 0 || WidgetsData.every(w => w.hidden)) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh' }}>
                <h3 className='text-muted' style={{ fontSize: '20px' }}>No widgets</h3>
            </div>
        );
    }

    const isNewAlertType = (category) => [
        'refillTooSoon',
        'pumpPickup',
        'narcoticsRefill'
    ].includes(category); 

    return (
        <>
            {/* <Row>
                <Col>
                    <h2>Pharmacy Alerts</h2>
                </Col>
            </Row> */}
            <Row className='justify-content-between mt-3'>
                {WidgetsData.filter(w => !w.hidden).sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1).map((item, index) => (
                    <Col key={index} xl={6}>
                        {
                            isNewAlertType(item.category) ? <AlertWidgetV2 hidden={item.hidden} key={index} item={item} setActiveAlert={setActiveAlert} />:
                            <AlertWidget hidden={item.hidden} key={index} item={item} setActiveAlert={setActiveAlert} setFaciToken={setFaciToken} />
                        }
                    </Col>
                ))}
                {activeAlert && <TableRowDetailModal isOpen={isModalOpen} toggle={toggleModal} alertInfo={activeAlert} />}
                {/* <Switch>
                    <Route path='/dashboard/:alertModal'  
                        component={(props) => (
                                <AlertWidgetLargeModal />
                                )}>
                    </Route>
                </Switch> */}
            </Row>
        </>
    );
};

const AlertWidget = ({ item, setActiveAlert, setFaciToken, hidden }) => {

    const { selectedFacilities } = useContext(SelectedFacilitiesContext);

    const [data, setData] = useAtom(item.atom);

    const [filteredAlerts, setFilteredAlerts] = useAtom(item.atomFilteredAlerts);
    let history = useHistory();

    // useEffect(() => {
    //     getData()
    // }, [])


    ///////!!!!!!!!!!!!!!!!!!!FAKE DATA

    // useEffect(() => {

    //     async function getData(){
    //         try{
    //             if(!item.hasOwnProperty('path'))
    //                 return
    //             const {data} = await SrxApi.get(`/${item.path}`)
    //             setAlerts(data[item.stateName])
    //             console.log(data[item.stateName])
    //         } catch(err){
    //             if(err.response?.status === 401){
    //                 localStorage.removeItem('FACI_TOKEN');
    //                 setFaciToken(null);
    //                 history.replace('/account/login')
    //             } else {
    //                 console.log(err)
    //             }
    //         } finally {
    //             setLoading(false)
    //         }
    //     }

    //     getData()

    // }, [])

    // const [onlyCallOnce, setOnlyCallOnce] = useState(false)

    // useEffect(() => {
    //     if(alerts.length && !onlyCallOnce){
    //         setOnlyCallOnce(true)
    //     setTimeout(() => filterNewAlerts(mockHighCostData), 20000)
    //     refreshDataTimerRef.current = setTimeout(() => {
    //         setAlerts(alerts.filter(alert => alert.displayStatus !== 'deleted'))
    //     }, 11 * 1000)
    //     }
    // }, [alerts])

    ///////!!!!!!!!!!!!!!!!!!! END OF FAKE DATA

    const [isHovered, setIsHovered] = useState(false);

    const [sortBy, setSortBy] = useState({
        sortField: item.defaultSortField,
        sortDirection: 'asc'
    });

    // const [filterByFacility(alerts), setFilteredByFacilityAlerts] = useState([])

    // useEffect(() => {
    //     setFilteredByFacilityAlerts(filterByFacility(alerts))
    // }, [selectedFacilities, alerts])
    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'dispenseDt':
                if (sortBy.sortDirection === 'asc') {
                    return array.sort((a, b) => a._generatedID > b._generatedID ? -1 : 1).sort((a, b) => new Date(a.dispenseDt) > new Date(b.dispenseDt) ? -1 : 1);
                }
                else {
                    return array.sort((a, b) => a._generatedID > b._generatedID ? -1 : 1).sort((a, b) => new Date(a.dispenseDt) < new Date(b.dispenseDt) ? -1 : 1);
                }
            case 'patName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.patName < b.patName ? -1 : 1);
                else
                    return array.sort((a, b) => a.patName > b.patName ? -1 : 1);
            case 'facName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facName < b.facName ? -1 : 1);
                else
                    return array.sort((a, b) => a.facName > b.facName ? -1 : 1);
            case 'facID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facID < b.facID ? -1 : 1);
                else
                    return array.sort((a, b) => a.facID > b.facID ? -1 : 1);
            case 'drugLabelName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.drugLabelName < b.drugLabelName ? -1 : 1);
                else
                    return array.sort((a, b) => a.drugLabelName > b.drugLabelName ? -1 : 1);
            case 'prescribedDrugName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.prescribedDrugName < b.prescribedDrugName ? -1 : 1);
                else
                    return array.sort((a, b) => a.prescribedDrugName > b.prescribedDrugName ? -1 : 1);
            case 'ttlPrice':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.ttlPrice < b.ttlPrice ? -1 : 1);
                else
                    return array.sort((a, b) => a.ttlPrice > b.ttlPrice ? -1 : 1);
            case 'admDate':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a._generatedID > b._generatedID ? -1 : 1).sort((a, b) => new Date(a.admDate) > new Date(b.admDate) ? -1 : 1);
                else
                    return array.sort((a, b) => a._generatedID > b._generatedID ? -1 : 1).sort((a, b) => new Date(a.admDate) < new Date(b.admDate) ? -1 : 1);
            case 'dateCreated':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a._generatedID > b._generatedID ? -1 : 1).sort((a, b) => new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1);
                else
                    return array.sort((a, b) => a._generatedID > b._generatedID ? -1 : 1).sort((a, b) => new Date(a.dateCreated) < new Date(b.dateCreated) ? -1 : 1);
            default:
                return array;
        }
    }

    const defaultNumOfAlerts = 100;

    const [numOfShownAlerts, setNumOfShownAlerts] = useState(defaultNumOfAlerts);

    const numOfAdditionalAlerts = () => {
        if ((filteredAlerts.length - numOfShownAlerts) >= defaultNumOfAlerts) {
            return defaultNumOfAlerts;
        } else if ((filteredAlerts.length > defaultNumOfAlerts) && (filteredAlerts.length - numOfShownAlerts) < defaultNumOfAlerts) {
            return filteredAlerts.length - numOfShownAlerts;
        } else {
            return 0;
        }
    };

    function add100Rows() {
        setNumOfShownAlerts(numOfShownAlerts + numOfAdditionalAlerts());
    }

    function getDate(date) {
        let onlyDate = new Date(date).toLocaleDateString();
        return onlyDate;
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    );

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            });
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            });
        }
    }

    // const [currentTime, setCurrentTime] = useState(Date.now())

    // useEffect(() => {
    //     let interval = setInterval(() => setCurrentTime(Date.now), 1 * 1000)
    //     return () => clearInterval(interval)
    // }, [])

    const { currentTime } = useContext(CurrentTimeContext);

    function displayDifferenceInMinutes(rfrshTime) {
        let differenceInMinutes = Math.floor((new Date(currentTime).getTime() - new Date(rfrshTime).getTime()) / 60000);

        if (differenceInMinutes < 1)
            return {
                minutes: differenceInMinutes,
                view: <small>Less than <span style={{ fontSize: '16px' }} ><b>1</b></span> minute ago</small>
            };
        else if (differenceInMinutes === 1)
            return {
                minutes: differenceInMinutes,
                view: <small><span style={{ fontSize: '16px' }}><b>{differenceInMinutes}</b></span> minute ago</small>
            };
        else if (differenceInMinutes >= 2 && differenceInMinutes <= 60)
            return {
                minutes: differenceInMinutes,
                view: <small><span style={{ fontSize: '16px' }}><b>{differenceInMinutes}</b></span> minutes ago</small>
            };
        else
            return {
                minutes: differenceInMinutes,
                view: <small>More than <span style={{ fontSize: '16px' }} ><b>1</b></span> hour ago</small>
            };
    }

    let differenceInMinutes = displayDifferenceInMinutes(data.lastRefreshed);

    const [rotateRefreshButton, setRotateRefreshButton] = useState(false);

    useEffect(() => {
        if (!isHovered)
            setRotateRefreshButton(false);
    }, [isHovered]);

    const { refreshWidget } = useRefreshAlerts();

    return (
        <Card hidden={hidden} style={{
            borderRadius: '11px', minHeight: '446.61px',
            opacity: !item.active ? '.30' : '',
            pointerEvents: !item.active ? 'none' : ''
        }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <CardHeader
                className='d-flex align-items-center justify-content-center bg-primary text-white position-relative gradient-modal'
                style={{
                    borderTopLeftRadius: '11px', borderTopRightRadius: '11px',
                    background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)"
                }}
            >
                <div className='position-relative' style={{ maxWidth: 'max-content' }}>
                    <strong>{item.name.toLocaleUpperCase()}</strong>
                    {item.active && <Badge className="position-absolute ml-1 d-flex justify-content-center align-items-center bg-danger
                                    print-all-badge"
                    >
                        {filteredAlerts.length}
                    </Badge>}
                </div>
                {data.lastRefreshed && <div className='position-absolute' style={{ left: '20px' }}>
                    <span className='elementToFadeInAndOut'><small>Updated</small></span>: {differenceInMinutes.view}
                    {differenceInMinutes.minutes >= 12 &&
                        <Button color='warning'
                            onClick={() => {
                                refreshWidget(item.path);
                            }}
                            className='py-0 px-1 ml-1 refresh-button'
                        ><small><b>Refresh</b></small></Button>}
                </div>}
                {isHovered &&
                    <Button color="link" className={`link-button p-0 d-flex align-items-center position-absolute ${rotateRefreshButton && 'refresh-widget-button-rotate'}`}
                        style={{ right: '50px', color: 'white' }}
                        onClick={() => {
                            setRotateRefreshButton(true);
                            refreshWidget(item.path);
                        }}
                        onAnimationEnd={() => setRotateRefreshButton(false)}
                        title="Refresh Alerts"
                    >
                        <Icon icon={refreshIcon} width="25" />
                    </Button>}
                {isHovered && <Button color="link" className='link-button p-0 d-flex align-items-center position-absolute'
                    style={{ right: '20px', color: 'white' }}
                    onClick={() => history.push('/alerts/' + item.path)}
                    title="Open Widget"
                >
                    <Icon icon={arrowTopRightBoldBoxOutline} width="25" />
                </Button>}
            </CardHeader>
            {data.loading ?
                <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                    <div>
                        {/* <h4>Loading alerts...</h4> */}
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                :
                <SimpleBar style={{ maxHeight: '400px' }}>
                    <CardBody className='pb-1 px-1 pt-0'>
                        <Table hover size='sm' className='mb-0'>
                            <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                <tr style={{ fontSize: '14px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {item.columns.map((col, index) => (
                                        <th key={index} className={`py-0 position-relative ${col.sort ? '' : 'noHover'}`} onClick={col.sort ? () => onClickSort(col.key) : null}
                                        >
                                            {col.icon && <Icon icon={col.icon} />} {col.name} {col.sort ? (sortBy.sortField === col.key) ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} /> : ''}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAlerts.length > 0 ?
                                    <>
                                        {sortByField(filteredAlerts).slice(0, numOfShownAlerts).map((alert, index) =>
                                        (
                                            <tr key={index} style={{ fontSize: '13px' }}
                                                className={classNames(
                                                    { unreadRow: !alert.readByFaci },
                                                    { readRow: alert.readByFaci },
                                                    { newRow: alert.displayStatus === 'new' },
                                                    { oldRow: alert.displayStatus === 'old' },
                                                    { deletedRow: alert.displayStatus === 'deleted' },
                                                    { approvedRow: alert.displayStatus === 'approved' },
                                                    { approvedOnceRow: alert.displayStatus === 'approvedOnce' },
                                                    { deniedRow: alert.displayStatus === 'denied' },
                                                    { askedRow: alert.displayStatus === 'asked' },
                                                    { sendRow: alert.displayStatus?.toLowerCase() === 'send' },
                                                    { doNotSendRow: alert.displayStatus?.toLowerCase() === 'donotsend' }
                                                )}
                                                onClick={() => {
                                                    setActiveAlert({
                                                        name: item.name,
                                                        alert: alert,
                                                        stateName: item.stateName,
                                                        category: item.category,
                                                        requestActionPermissionWidgetName: item.requestActionPermissionWidgetName
                                                    });
                                                }}
                                            >
                                                {item.columns.map((col, index) => (
                                                    <td key={index} style={{ verticalAlign: 'middle' }} className={col?.className}>{col.prefix}{col.format ? col.format(alert[col.key], alert.rxGuid, alert) : alert[col.key]}{col.suffix}</td>
                                                ))}
                                                {/* <td style={{ verticalAlign: 'middle' }}>{alert.patName}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{alert.facName}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{alert.drugLabelName}</td>
                                                <td style={{ verticalAlign: 'middle' }}>${alert.ttlPrice}</td> */}
                                                {/* <td>{statusPill(alert.status)}</td> */}
                                                {/* <td style={{ verticalAlign: 'middle' }}>${alert.priceCap}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{getDate(alert.dispenseDt)}</td> */}
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan="100%" align="center">
                                                {filteredAlerts.length ?
                                                    numOfAdditionalAlerts() > 0 ? <Button color="link"
                                                        onClick={() => add100Rows(item.stateName)}>
                                                        <b>Show {numOfAdditionalAlerts()} more...</b>
                                                    </Button>
                                                        :
                                                        <span><b>End of alerts</b></span>
                                                    :
                                                    <span><b>No alerts</b></span>
                                                }
                                            </td>
                                        </tr>
                                    </>
                                    :
                                    <tr>
                                        <td colSpan="6" align="center" style={{ fontSize: '30px', backgroundColor: 'white' }}>
                                            {/* <b>Coming Soon</b> */}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                </SimpleBar>
            }
            {/* <Badge className="position-absolute ml-1 d-flex justify-content-center align-items-center bg-danger
                            print-all-badge"
                            >
                                { item.stateName === 'highCostAlerts'  && highCostAlerts.length }
                            </Badge> */}
        </Card>
    );
};

const AlertWidgetV2 = ({ hidden, item, setActiveAlert }) => {
    const [pharmacyAlert, setPharmacyAlert] = useAtom(item.atom);

    const { selectedFacilities } = useContext(SelectedFacilitiesContext);

    let history = useHistory()

    useEffect(() => {
        setReactSucksHook(!reactSucksHook);

        loadAlertsOnFilter();
    }, [selectedFacilities]);

    const [isHovered, setIsHovered] = useState(false);

    const [rotateRefreshButton, setRotateRefreshButton] = useState(false);

    const [sortBy, setSortBy] = useState({
        sortField: item.defaultSortField,
        sortDirection: 'asc'
    });

    const [reactSucksHook, setReactSucksHook] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(refreshData, 30000);
    
        return () => clearInterval(intervalId); // Cleanup on unmount or dependency change
    }, [reactSucksHook]);

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    );

    const onClickSort = (field) => {
        let direction = 'asc';

        if (field === sortBy.sortField) {
            direction = sortBy.sortDirection === 'asc' ? 'desc' : 'asc';
        }

        setPharmacyAlert({
            ...pharmacyAlert,
            isSorted: true,
        });

        setSortBy({
            sortField: field,
            sortDirection: direction
        });

        setReactSucksHook(!reactSucksHook);

        loadAlertsOnSort(field, direction);
    }

    const defaultNumOfAlerts = 100;

    const [numOfShownAlerts, setNumOfShownAlerts] = useState(defaultNumOfAlerts);

    const numOfAdditionalAlerts = () => {
        if ((pharmacyAlert.alertData.length - numOfShownAlerts) >= defaultNumOfAlerts) {
            return defaultNumOfAlerts;
        } else if ((pharmacyAlert.length > defaultNumOfAlerts) && (pharmacyAlert.length - numOfShownAlerts) < defaultNumOfAlerts) {
            return pharmacyAlert.length - numOfShownAlerts;
        } else {
            return 0;
        }
    };

    const alertsUrl = (more = false) => {
        let url =`${item.path}?`;

        if(pharmacyAlert.isSorted){
            url += `sort=${sortBy.sortField}&sortOrder=${sortBy.sortDirection}`;
            if (more) url += `&page=${pharmacyAlert.nextPage}`;
        }else if (more) {
            url += `cursor=${pharmacyAlert.cursor}`;
        }

        // IF facilities is not null add the facilities to the query parameter, a new query parameter will be added for each facility not separated by commas
        if (selectedFacilities) {
            selectedFacilities.forEach((facility) => {
                url += `&facilityIds=${facility.facID}`;
            });
        }

        return url;
    };

    function add100Rows() {
        setNumOfShownAlerts(numOfShownAlerts + numOfAdditionalAlerts());
    }

    const loadData = async (more = true) => {
        let { data } = await SrxApi.get(alertsUrl(more));

        const newData = more ? [...pharmacyAlert.alertData, ...data.results] : data.results;

        setPharmacyAlert({
            ...pharmacyAlert,
            alertData: newData,
            cursor: data.cursor,
            lastRefreshed: data.timeStamp,
            loading: false,
            error: false,
            alertCount: data.count,
            nextPage: data.nextPage,
            isSorted: pharmacyAlert.isSorted,
        });

        add100Rows();
    }

    const refreshData = async () => {
        const _url = new URL(window.location.origin + '/' + alertsUrl());

        _url.searchParams.delete('page');

        _url.searchParams.delete('cursor');

        let { data } = await SrxApi.get(_url.pathname + _url.search);

        // Extract the top {data.length} alerts from pharmacyAlert.alertData
        const topAlerts = pharmacyAlert.alertData.slice(0, data.results.length);

        // Filter out alerts in freshData that are already in topAlerts
        const newData = data.results.filter((alert) => !topAlerts.some((oldAlert) => oldAlert.rxGuid === alert.rxGuid));

        // If newData is empty, return
        if (!newData.length) return;

        // Add the rest to the top of the pharmacyAlert.alertData
        const updatedData = [...newData, ...pharmacyAlert.alertData];

        // This is not 100% accurate because if we received new data
        // and {page} is used for pagination,
        // we will end up skipping a few records.
        // But it's close enough
        setPharmacyAlert({
            ...pharmacyAlert,
            alertData: updatedData,
            cursor: pharmacyAlert.cursor,
            lastRefreshed: data.timeStamp,
            loading: false,
            error: false,
            alertCount: data.count,
            nextPage: pharmacyAlert.nextPage,
            isSorted: pharmacyAlert.isSorted,
        });
    }

    
    const loadAlertsOnSort = async (sortField, sortDirection) => {


        var url =`${item.path}?sort=${sortField}&sortOrder=${sortDirection}&page=1`;

        if (selectedFacilities) {
            selectedFacilities.forEach((facility) => {
                url += `&facilityIds=${facility.facID}`;
            });
        }

        let { data } = await SrxApi.get(url);

        setPharmacyAlert({
            ...pharmacyAlert,
            alertData: data.results,
            cursor: data.cursor,
            lastRefreshed: data.timeStamp,
            loading: false,
            error: false,
            alertCount: data.count,
            nextPage: data.nextPage,
            isSorted: true,
        });

        add100Rows();
    }

    const loadAlertsOnFilter = async () => {

        var facilities = selectedFacilities;

        var url =`${item.path}?`;

        if (facilities) {
            facilities.forEach((facility) => {
                url += `&facilityIds=${facility.facID}`;
            });
        }

        if(pharmacyAlert.isSorted){
            url += `&sort=${sortBy.sortField}&sortOrder=${sortBy.sortDirection}`;
        }
    
            let { data } = await SrxApi.get(url);
    
            setPharmacyAlert({
                ...pharmacyAlert,
                alertData: data.results,
                cursor: data.cursor,
                lastRefreshed: data.timeStamp,
                loading: false,
                error: false,
                alertCount: data.count,
                nextPage: data.nextPage,
                isSorted: pharmacyAlert.isSorted,
            });
    
            add100Rows();
        }

    return (
        <Card hidden={hidden} style={{
            borderRadius: '11px', minHeight: '446.61px',
            opacity: !item.active ? '.30' : '',
            pointerEvents: !item.active ? 'none' : ''
        }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <CardHeader
                className='d-flex align-items-center justify-content-center bg-primary text-white position-relative gradient-modal'
                style={{
                    borderTopLeftRadius: '11px', borderTopRightRadius: '11px',
                    background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)"
                }}
            >
                <div className='position-relative' style={{ maxWidth: 'max-content' }}>
                    <strong>{item.name.toLocaleUpperCase()}</strong>
                    {item.active && <Badge className="position-absolute ml-1 d-flex justify-content-center align-items-center bg-danger
                                    print-all-badge"
                    >
                       {pharmacyAlert.alertData.length + ' of ' + pharmacyAlert.alertCount}
                    </Badge>}
                </div>

                {isHovered &&
                    <Button color="link" className={`link-button p-0 d-flex align-items-center position-absolute ${rotateRefreshButton && 'refresh-widget-button-rotate'}`}
                        style={{ right: '50px', color: 'white' }}
                        onClick={() => {
                            setRotateRefreshButton(true);
                            loadData(false);
                        }}
                        onAnimationEnd={() => setRotateRefreshButton(false)}
                        title="Refresh Alerts"
                    >
                        <Icon icon={refreshIcon} width="25" />
                    </Button>
                }
{/* TODO - display print option only for widgets that support it */}
                {item.listPrint && isHovered &&
                    <Button
                        color="link"
                        className='link-button p-0 d-flex align-items-center position-absolute'
                        style={{ right: '80px', color: 'white' }}
                        title="Print PDF"
                        onClick={() => {
                            let doc = item["listPrintFunction"](pharmacyAlert.alertData);
                            doc.autoPrint();
                            window.open(doc.output('bloburl'), '_blank');
                        }}>
                        <Icon icon={printerIcon} width="25" />
                    </Button>
                }

                {isHovered && 
                    <Button color="link" className='link-button p-0 d-flex align-items-center position-absolute'
                        style={{ right: '20px', color: 'white' }}
                        onClick={() => history.push('/alerts/' + item.path)}
                        title="Open Widget"
                    >
                        <Icon icon={arrowTopRightBoldBoxOutline} width="25" />
                    </Button>
                }
            </CardHeader>
            {pharmacyAlert.loading ?
                <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                    <div>
                        {/* <h4>Loading alerts...</h4> */}
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                :
                <SimpleBar style={{ maxHeight: '400px' }}>
                    <CardBody className='pb-1 px-1 pt-0'>
                        <Table hover size='sm' className='mb-0'>
                            <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
                                <tr style={{ fontSize: '14px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {item.columns.map((col, index) => (
                                        <th key={index} className={`py-0 position-relative ${col.sort ? '' : 'noHover'}`} onClick={col.sort ? () => onClickSort(col.key) : null}
                                        >
                                            {col.icon && <Icon icon={col.icon} />} {col.name} {col.sort ? (sortBy.sortField === col.key) ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} /> : ''}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {pharmacyAlert.alertData?.length > 0 ?
                                    <>
                                        {pharmacyAlert.alertData.map((alert, index) =>
                                        (
                                            <tr key={index} style={{ fontSize: '13px' }}
                                                className={classNames(
                                                    { unreadRow: !alert.readByFaci },
                                                    { readRow: alert.readByFaci },
                                                    { newRow: alert.displayStatus === 'new' },
                                                    { oldRow: alert.displayStatus === 'old' },
                                                    { deletedRow: alert.displayStatus === 'deleted' },
                                                    { approvedRow: alert.displayStatus === 'approved' },
                                                    { approvedOnceRow: alert.displayStatus === 'approvedOnce' },
                                                    { deniedRow: alert.displayStatus === 'denied' },
                                                    { askedRow: alert.displayStatus === 'asked' },
                                                    { sendRow: alert.displayStatus?.toLowerCase() === 'send' },
                                                    { doNotSendRow: alert.displayStatus?.toLowerCase() === 'donotsend' }
                                                )}
                                                onClick={() => {
                                                    setActiveAlert({
                                                        name: item.name,
                                                        alert: alert,
                                                        stateName: item.stateName,
                                                        category: item.category,
                                                        requestActionPermissionWidgetName: item.requestActionPermissionWidgetName
                                                    });
                                                }}
                                            >
                                                {item.columns.map((col, index) => (
                                                    <td key={index} style={{ verticalAlign: 'middle' }} className={col?.className}>{col.prefix}{col.format ? col.format(alert[col.key], alert.rxGuid, alert) : alert[col.key]}{col.suffix}</td>
                                                ))}
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan="100%" align="center">
                                                {pharmacyAlert.alertData.length ?
                                                    pharmacyAlert.alertData.length < pharmacyAlert.alertCount ? <Button color="link"
                                                        onClick={() => loadData()}>
                                                        <b>Show more...</b>
                                                    </Button>
                                                        :
                                                        <span><b>End of alerts</b></span>
                                                    :
                                                    <span><b>No alerts</b></span>
                                                }
                                            </td>
                                        </tr>
                                    </>
                                    :
                                    <tr>
                                        <td colSpan="6" align="center" style={{ fontSize: '30px', backgroundColor: 'white' }}>
                                            {/* <b>Coming Soon</b> */}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                </SimpleBar>
            }
            {/* <Badge className="position-absolute ml-1 d-flex justify-content-center align-items-center bg-danger
                            print-all-badge"
                            >
                                { item.stateName === 'highCostAlerts'  && highCostAlerts.length }
                            </Badge> */}
        </Card>
    );
};

export default AlertsDashboard;